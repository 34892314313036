<template>
  <v-container grid-list-xl text-xs-center>
    <v-layout row wrap align-center>
      <v-flex xs12>
        <v-list-item-title class="headline">Homework Submission</v-list-item-title>
        <v-list-item-subtitle>Student Number: {{user && user.StudentID}}</v-list-item-subtitle>
        <v-list-item-subtitle>Welcome {{user && user.GivenName}} {{user && user.Surname}}</v-list-item-subtitle>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row v-if="user.RegionID !== 8">
              <v-select v-model="selectRegion_year" :items="years" item-text="FullName" item-value="Region_yearID" :rules="[v => (!!v && v.Region_yearID !== '') || 'Year is required']" label="Year (Course level at North Shore)" clearable></v-select>
            </v-row>
            <v-row>
              <v-select v-model="selectTerm" :items="terms" item-text="Name" item-value="TermID" :rules="[v => (!!v && v.TermID !== '') || 'Term is required']" label="Term" return-object clearable></v-select>
            </v-row>
            <v-row v-if="user.RegionID === 8">
              <v-select v-model="selectHK_year" :disabled="!selectTerm" :items="filteredYears" item-text="FullName" item-value="Region_yearID" :rules="[v => (!!v && v.Region_yearID !== '') || 'Year is required']" label="Year (Course level at North Shore)" clearable></v-select>
            </v-row>
            <v-row>
              <v-select v-model="selectWeek" :items="weeks" item-text="Name" item-value="WeekID" :rules="[v => (!!v && v.WeekID !== '') || 'Week is required']" label="Week/Day(Holiday)" clearable></v-select>
            </v-row>
            <v-row>
              <v-autocomplete v-model="selectSubject" :disabled="user.RegionID === 8 && !selectTerm" :items="filteredSubjects" item-text="Name" item-value="SubjectID" label="Subject" :rules="[v => (!!v && v.SubjectID !== '') || 'Subject is required']" clearable></v-autocomplete>
            </v-row>
            <v-row>
              <v-file-input
                v-model="files"
                :rules="imageRules"
                accept=".pdf,image/*"
                placeholder="Browse your homework images or pdf files here" 
                label="Allowed image and pdf file types only. Maximum size 4MB per file. Maximum 20 files." 
                multiple 
                prepend-icon="mdi-paperclip"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-row>
            <v-row>
              <v-btn :disabled="!valid || !files.length" color="primary" class="mr-4" @click="uploadHomework" :loading="isLoading">Upload</v-btn>
              <v-alert dense outlined type="error">
                Please submit homework pages for <strong>marking</strong> only
              </v-alert>
            </v-row>
            <v-row v-if="user.RegionID === 2">
              <v-alert
                border="bottom"
                type="info"
                colored-border
                elevation="2"
              >
                Face-to-face students, please hand homework to teacher in class
              </v-alert>
            </v-row>
          </v-form>
        </v-card-text>
      </v-flex> 
      <v-flex xs12>
        <v-card>
          <v-card-title>
            Homework Status
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="homeworks" :items-per-page="15" :custom-filter="customFilter" :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}" :search="search" class="elevation-1" :loading="isLoading" loading-text="Loading... Please wait" item-key="HomeworkID">
            <template v-slot:[`item.SubmittedOn`]="{ item }">
              <span>{{ item.SubmittedOn | formatDateTime }}</span>
            </template>
            <template v-slot:[`item.IsMarked`]="{ item }">
              <v-icon medium v-if="(!!item.IsMarked)==true" class="mr-2">
                mdi-check
              </v-icon>
            </template>
            <template v-slot:[`item.homework`]="{ item }">
              <v-btn class="mx-2" fab dark small color="primary" :loading="item.downloadloading" :disabled="item.downloadloading" @click="showHomeworkDialog = true; selectedItem_ViewHomework = item;">
                <v-icon dark>mdi-file-eye-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.award`]="{ item }">
              <v-btn class="mx-2" v-if="item.AwardID" fab dark small color="orange" :loading="item.awardloading" :disabled="item.awardloading" @click="showAwardDialog = true; selectedItem_ViewAward = item;">
                <v-icon dark>mdi-certificate</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon v-if="(!!item.IsMarked)==false" class="mr-2" @click="showEditHomeworkDialog = true; selectedItem_EditHomework = item;">mdi-pencil</v-icon>
              <v-icon v-if="(!!item.IsMarked)==false" @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <div class="text-center">
      <v-dialog width="800" v-model="showHomeworkDialog" persistent>
        <view-homework :editedItem="selectedItem_ViewHomework" @close="showHomeworkDialog = false" :key="`viewhomework.${selectedItem_ViewHomework.HomeworkID}`"></view-homework>
      </v-dialog>
      </div>   
      <v-dialog width="800" v-model="showAwardDialog" persistent>
        <view-award :editedItem="selectedItem_ViewAward" @close="showAwardDialog = false" :key="`viewaward.${selectedItem_ViewAward.HomeworkID}`"></view-award>
      </v-dialog>
      <v-dialog width="800" v-model="showEditHomeworkDialog" persistent>
        <edit-homework :editedItem="selectedItem_EditHomework" :weeks="weeks" @edit="editItem" @close="showEditHomeworkDialog = false" :key="`edithomework.${selectedItem_EditHomework.HomeworkID}`"></edit-homework>
      </v-dialog>
      <confirm-dialog ref="confirm"></confirm-dialog>   
      <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
        {{ snackbarText }}
        <v-btn dark text @click="snackbar = false">
          Close
        </v-btn>
      </v-snackbar>
      <div class="text-center">
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </div>
    </v-layout>
  </v-container> 
</template>

<script>
import ViewHomework from '@/components/ViewHomework'
import ViewAward from '@/components/ViewAward'
import ConfirmDialog from '@/components/ConfirmDialog'
import EditHomework from '@/components/EditHomework'
import { mapState } from 'vuex'

const _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png", ".pdf"];

export default {
  name: 'HomeworkUpload',
  components: {
    ViewHomework,
    ViewAward,
    ConfirmDialog,
    EditHomework
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          align: 'left',
          sortable: false,
          value: 'HomeworkID'
        },
        { text: 'Filename', value: 'FileName' },
        { text: 'Year', value: 'Region_Year.FullName' }, 
        { text: 'Term', value: 'Term.Name' }, 
        { text: 'Week/ Day(Holiday)', value: 'WeekID' },
        { text: 'Subject', value: 'Subject.Name' },
        { text: 'Date Submitted', value: 'SubmittedOn' },
        { text: 'Marked?', value: 'IsMarked' },
        { text: 'View Homework', value: 'homework', sortable: false },
        { text: 'Good Student Award', value: 'award', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false, width: "100" },
      ],
      search: null,
      isLoading: false,
      valid: false,
      selectTerm: null,
      selectSubject: {Name: '', SubjectID: ''},
      files: [],
      selectWeek: null,
      selectRegion_year: null,
      selectHK_year: null,
      weeks: [
        {Name: '1', WeekID: 1}, 
        {Name: '2', WeekID: 2}, 
        {Name: '3', WeekID: 3},
        {Name: '4', WeekID: 4},
        {Name: '5', WeekID: 5},
        {Name: '6', WeekID: 6},
        {Name: '7', WeekID: 7},
        {Name: '8', WeekID: 8},
        {Name: '9', WeekID: 9},
        {Name: '10', WeekID: 10}
      ],
      imageRules: [
        v => !v.length || (v.length < 20 && v.some(file => file.size < 4000000)) || 'Max 20 files. Each file size should be less than 4 MB!'
      ],
      overlay: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      timeout: 6000,
      showHomeworkDialog: false,
      showAwardDialog: false,
      showEditHomeworkDialog: false,
      selectedItem_ViewHomework: { Term: '', WeekID: '', YearID: '', HomeworkID: '', Student: {GivenName: '', Surname: ''}},
      selectedItem_ViewAward: { Term: '', WeekID: '', YearID: '', HomeworkID: '', Student: {GivenName: '', Surname: ''}},
      selectedItem_EditHomework: { Term: '', WeekID: '', YearID: '', HomeworkID: '', Student: {GivenName: '', Surname: ''}},
    }
  },
  computed: {
    filteredYears() {
      if (this.selectTerm) {
        if (this.selectTerm.Name.toLowerCase().includes("(au)"))
          return this.years.filter(y => y.KeyName.toLowerCase().includes("au-"));
        return this.years.filter(y => y.KeyName.toLowerCase().includes("hk-"));  
      }
      return [];
    },
    filteredSubjects() {
      if (this.selectTerm && this.user.RegionID === 8) {
        if (this.selectTerm.Name.toLowerCase().includes("(au)"))
          return this.subjects.filter(s => !s.RegionID);
        return this.subjects.filter(s => s.RegionID === 8);
      }
      return this.subjects;
    },
    ...mapState(["user", "homeworks", "terms", "subjects", "years"])
  },
  mounted () {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.overlay = true;
        await Promise.all([
          this.$store.dispatch('getStudentHomeworks'), 
          this.$store.dispatch('getTerms'), 
          this.$store.dispatch('getSubjects'),
          this.$store.dispatch('getYears')
        ]);
        this.selectRegion_year = this.user.Region_yearID;
      } finally {
        this.overlay = false;
      }
    },
    validateFileTypes() {
      for( var i = 0; i < this.files.length; i++ ){
        const file = this.files[i];
        const sFileName = file.name;
        var isValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
            isValid = true;
            break;
          }
        }
        if (!isValid) {
          this.snackbar = true;
          this.snackbarText = "Sorry, unable to upload. Allowed extensions are: " + _validFileExtensions.join(", ");
          this.snackbarColor = "error";
          return false;
        }
      }
      return true;
    },
    async uploadHomework() {
      if (this.$refs.form.validate() && this.validateFileTypes()) {
        try {
          this.isLoading = true;
          let formData = new FormData();
          formData.append('Region_yearID', this.user.RegionID === 8 ? this.selectHK_year : this.selectRegion_year);
          formData.append('TermID', this.selectTerm.TermID);
          formData.append('WeekID', this.selectWeek);
          formData.append('SubjectID', this.selectSubject);
          for( var i = 0; i < this.files.length; i++ ){
            let file = this.files[i];
            formData.append('files', file);
          }
          const result = await this.$store.dispatch('submitStudentHomework', formData);
          this.snackbar = true;
          if (!!result === true) {
            this.snackbarColor = "success";
            this.snackbarText = "Uploaded successfully";
          } else {
            this.snackbarColor= "error";
            this.snackbarText = "Failed to upload. The file might be corrupted, truncated, or in an unexpected format.";
          }
          this.files = [];
        } finally {
          this.isLoading = false;
        }
      }
    },
    async editItem(region_yearID, termID, subjectID, weekID) {
      const params = {
        HomeworkID: this.selectedItem_EditHomework.HomeworkID,
        Region_yearID: region_yearID,
        TermID: termID,
        SubjectID: subjectID,
        WeekID: weekID
      };
      const result = await this.$store.dispatch('updateHomework', { editedItem: this.selectedItem_EditHomework, params } );
      this.snackbar = true;
      if (!!result === true) {
        this.snackbarColor = "success";
        this.snackbarText = "Homework updated successfully";
      } else {
        this.snackbarColor= "error";
        this.snackbarText = "Failed to update homework. Please try again";
      }
      this.showEditHomeworkDialog = false;
    },
    async deleteItem(item) {
      if (await this.$refs.confirm.open('Delete', `Are you sure you want to delete this homework ID ${item.HomeworkID}?`, { color: 'red' })) {
        const result = await this.$store.dispatch('deleteHomework', { editedItem: item } );
        this.snackbar = true;
        if (!!result === true) {
          this.snackbarColor = "success";
          this.snackbarText = "Homework deleted successfully";
        } else {
          this.snackbarColor= "error";
          this.snackbarText = "Failed to delete homework. Please try again";
        }
      }
    },
    customFilter (value, search, item) {
      return value != null &&
        search != null &&
        ( value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          this.$options.filters.formatDateTime(item.SubmittedOn).toString().indexOf(search) !== -1
        )
    },
  }
}
</script>