<template>
  <v-card>
    <v-card-title>
      <span class="headline">Edit Homework ID {{ editedItem && editedItem.HomeworkID }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form" lazy-validation>
          <v-row justify="center">
            <v-col cols="12">
              <v-select v-model="selectRegion_year" :items="years" item-text="FullName" item-value="Region_yearID" :rules="[v => (!!v && v.Region_yearID !== '') || 'Year is required']" label="Year" clearable></v-select>
            </v-col>
            <v-col cols="12">
              <v-select v-model="selectTerm" :items="terms" item-text="Name" item-value="TermID" :rules="[v => (!!v && v.TermID !== '') || 'Term is required']" label="Term" clearable></v-select>
            </v-col>
            <v-col cols="12">
              <v-select v-model="selectWeek" :items="weeks" item-text="Name" item-value="WeekID" :rules="[v => (!!v && v.WeekID !== '') || 'Week is required']" label="Week/Day(Holiday)" clearable></v-select>
            </v-col>
            <v-col cols="12">
              <v-autocomplete v-model="selectSubject" :items="subjects" item-text="Name" item-value="SubjectID" label="Subject" :rules="[v => (!!v && v.SubjectID !== '') || 'Subject is required']" clearable></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>  
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
      <v-btn color="blue darken-1" text @click="save">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'EditHomework',
  props: {
    editedItem: Object,
    weeks: Array,
  },
  data () {
    return {
      selectRegion_year: null,
      selectTerm: null,
      selectSubject: null,
      selectWeek: null,
    }
  },
  computed: {
    ...mapState(["years","terms", "subjects"])
  },
  mounted() {
    this.selectRegion_year = this.editedItem.Region_yearID;
    this.selectTerm = this.editedItem.TermID;
    this.selectSubject = this.editedItem.SubjectID;
    this.selectWeek = this.editedItem.WeekID;
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        this.$emit('edit', this.selectRegion_year, this.selectTerm, this.selectSubject, this.selectWeek);
      }
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>
