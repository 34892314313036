<template>
  <v-card class="mx-auto">
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-img
              :src="image"
              aspect-ratio = 1
              class="white--text align-end grey darken-4"
              contain
            >
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>    
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="download">Download</v-btn>
      <v-btn text @click="$emit('close');">Close</v-btn>
    </v-card-actions>
    <div class="text-center">
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>  
  </v-card>
</template>

<script>
import { httpClient } from '../services/http-client';
var FileSaver = require('file-saver');

export default {
  name: 'ViewHomework',
  props: {
    editedItem: Object,
  },
  data () {
    return {
      isLoading: false,
      image: '',
      header: null,
    }
  },
  async mounted() {
    try {
      this.isLoading = true;
      if (this.editedItem && this.editedItem.HomeworkID) {
        const response = await httpClient.get(`/homework/image/${this.editedItem.HomeworkID}`, { responseType:"arraybuffer" });
        let data = btoa(
          new Uint8Array(response.data)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        this.header = `data:${response.headers['content-type'].toLowerCase()};base64,`;
        this.image = `${this.header}${data}`
      }
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    download() {
      try {
        this.isLoading = true;
        if (this.image) {
          var data = this.image.replace(this.header, "");
          var buf = new Buffer(data, 'base64');
          FileSaver.saveAs(new Blob([buf]), `${this.editedItem.HomeworkID}.${this.editedItem.FileExt}`);
        }
 
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script> 