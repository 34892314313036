<template>
  <v-card class="mx-auto">
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-img
              :src="image"
              aspect-ratio = 1
              class="white--text align-end grey darken-4"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              contain
            >
              <v-card-title>Good Student Award</v-card-title>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>    
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="download">Download</v-btn>
      <v-btn text @click="$emit('close');">Close</v-btn>
    </v-card-actions>
    <div class="text-center">
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>  
  </v-card>
</template>

<script>
import { httpClient } from '../services/http-client';
var FileSaver = require('file-saver');

export default {
  name: 'ViewAward',
  props: {
    editedItem: Object,
  },
  data () {
    return {
      isLoading: false,
      image: '',
    }
  },
  async mounted() {
    try {
      this.isLoading = true;
      if (this.editedItem && this.editedItem.HomeworkID) {
        const response = await httpClient.get(`/award/download/homework/${this.editedItem.HomeworkID}`);
        this.image = response.data;
      }
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    download() {
      try {
        this.isLoading = true;
        if (this.image) {
          var data = this.image.replace(/^data:image\/\w+;base64,/, "");
          var buf = new Buffer(data, 'base64');
          FileSaver.saveAs(new Blob([buf]), `Good Student Award - ${this.editedItem.Award.StudentFirstName}.png`);
        }
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script> 